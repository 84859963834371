import ApplicationController from "./application_controller"

export default class extends ApplicationController {

  static targets = [
    'alternativeHistoriesModal',
    'saveButton'
  ];

  initialize() {
    window['spelling_sources'] = this;
    //this.#assignDefaultValues()
    this.addShowHideListenersToLatinHistoryModal();
  }

  // #assignDefaultValues(){
  //   this.isLatinHistoryModalOpen = false;
  // }
  updateButtonText() {
    this.saveButtonTarget.innerHTML = `Saved <i class="feather-check sm-icon" data-feather="check"></i>`;
    this.saveButtonTarget.classList.add('sources-saved-btn');
    
    feather.replace();

    setTimeout(() => {
      this.saveButtonTarget.textContent = "Save";
      this.saveButtonTarget.classList.remove('sources-saved-btn');
    }, 2000);
  }

  addShowHideListenersToLatinHistoryModal() {
    const modalElement = this.alternativeHistoriesModalTarget;
    modalElement.addEventListener('show.bs.modal', () => {
      isLatinHistoryModalOpen = true;
      // console.log("Modal is now open");
      // console.log(this.isLatinHistoryModalOpen)
    });

    modalElement.addEventListener('hide.bs.modal', () => {
      isLatinHistoryModalOpen = false;
      // console.log("Modal is now closed");
      // console.log(this.isLatinHistoryModalOpen)
    });		

    modalElement.addEventListener("hidden.bs.modal", this.updateSourceIconColor.bind(this));
  }

  // This method updates sources icon color to red if sources url/pageno value is added
  // and removes red icon color if sources url/page no. values is removed
  updateSourceIconColor(e) {
    const modalElement = e.currentTarget;
    const sourcesFields = modalElement.querySelectorAll('.js-nested-form-wrapper');
    let isSourceValuePresent = false;

    sourcesFields.forEach(sourceField => {
      const urlInput = sourceField.querySelector('.js-source-url-input');
      const pageNumberInput = sourceField.querySelector('.js-source-page-no-input');

      if (urlInput.value.length > 1 || pageNumberInput?.value?.length > 1 ){ 
        isSourceValuePresent = true;
        return
      }
    });

    if (isSourceValuePresent == true) {
      this.currentTypingFieldContainer(modalElement).querySelector('.js-alternate-history-sources-btn').classList.add('text-bright-red');
    } else {
      this.currentTypingFieldContainer(modalElement).querySelector('.js-alternate-history-sources-btn').classList.remove('text-bright-red');
    }
  }
}
