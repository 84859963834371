import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["ottomanValusOption"]

  initialize(){
    window['typeo_prepopulation'] = this;
    const otherDropdownOptions = this.getOtherBtnDropdownData(); // Getting dropdown's spelling values, flags and sources
    
    if (!this.isBlankDropdownValues(otherDropdownOptions)){
      //show other btn if dropdown has options
      this.showOtherBtn();
      this.removePopulatedOptionFromOtherBtnDropdown();
    }
    // 'js-unpopulated-history-sources' uniquely identifies fields for Latin alternative fields sources
    // to be populated from 'other' dropdown. Removing this class from prepopulated alternative fields
    // since they are already populated.
    this.removeSourcesIdentifierFromNewPopulatedAlternateSpellingField();
  }

  populateNextSpellingsIfTabPressed(event){
    // Early return is temporarily added to disable auto-population until the code is updated to align with the spelling-linkages.
    return
    if (event.key === 'Tab') {
      event.preventDefault();
      this.populateNextSpellingsIfSavedMatchingSpellindIsFound(event);
    }
  }

  populateNextSpellingsIfSavedMatchingSpellindIsFound(e){
    const testFieldValue       = e.target.value;
    const targetFieldContainer = e.target.closest('.entries-input');
    const targetTextField      = targetFieldContainer.querySelector('.js-text-field');
    const targetTextFieldType  = this.getTextFieldType(targetTextField);
    const typeoFieldsContainer = document.getElementById('typeo-fields-container');
    const currentPageSetId     = typeoFieldsContainer.dataset.currentPageSet;
    const currentWordId        = typeoFieldsContainer.dataset.currentWord;

    const currentFieldMarkedAsRoot       = targetFieldContainer?.querySelector('.js-marked-as-root-toggle')?.checked
    const currentFieldMarkedAsRootLetter = targetFieldContainer?.querySelector('.js-marked-as-root-letter-toggle')?.checked
    // const currentFieldMarkedAsArabic     = targetFieldContainer?.querySelector('.js-marked-as-arabic-toggle')?.checked
    const currentFieldMarkedAsArabic = targetTextFieldType === 'primary' 
    ? targetFieldContainer.closest('.js-otto-container')?.querySelector('.js-root-parent-container .js-marked-as-arabic-toggle')?.checked 
    : targetFieldContainer?.querySelector('.js-marked-as-arabic-toggle')?.checked;


    const currentFieldMarkedAsSingular   = targetFieldContainer?.querySelector('.js-marked-as-singular-toggle')?.checked
    const currentFieldMarkedAsUnknown    = targetFieldContainer?.querySelector('.js-marked-as-unknown-toggle')?.checked

    const currentFieldMarkedAsDerived    = targetFieldContainer?.querySelector('.js-marked-as-derived-value-toggle')?.checked
    const currentFieldMarkedAsPlural     = targetFieldContainer?.querySelector('.js-marked-as-plural-value-toggle')?.checked
    const currentFieldMarkedAsOpposite   = targetFieldContainer?.querySelector('.js-marked-as-opposite-value-toggle')?.checked
    const currentFieldMarkedAsRelated    = targetFieldContainer?.querySelector('.js-marked-as-related-value-toggle')?.checked

    // alternativeAssignedValue represnets the order of alternate text field
    let alternativeAssignedValue = this.getAlternativeAssignedValue(targetTextField);

    if (currentFieldMarkedAsRootLetter == "true") {
      return;
    }

    // $.ajax({
    //   url: '/page_set/typeo_prepopulation/latest_saved_identical_ottoman_spelling_alternates',
    //   type: 'GET',
    //   contentType: 'application/json',
    //   data: {
    //      ottoman_text: testFieldValue,
    //      is_marked_as_root_letter: isMarkedAsRootLetter,
    //      page_set: currentPageSetId,
    //      current_word: currentWordId
    //   },
    //   success: (data) => {
    //       this.handlePopulationOfAlternateSpellings(data.alternate_spellings_data_to_populate, e.target, alternativeAssignedValue);
    //     },
    //   error: function(xhr, status, error) {
    //     console.error('Error:', error);
    //   }
    // });

    $.ajax({
      // url: '/page_set/typeo_prepopulation/find_suggested_ottoman_values',
      url: '/page_set/typeo_prepopulation/latest_saved_identical_ottoman_spelling_alternates1',
      type: 'GET',
      data: {
         ottoman_text: testFieldValue,
         spelling_type: targetTextFieldType,
         page_set: currentPageSetId,
         current_word: currentWordId,
         target_element_id: e.target.id,
         
         current_field_marked_as_root_toggle: currentFieldMarkedAsRoot,
         current_field_marked_as_root_letter_toggle: currentFieldMarkedAsRootLetter,
         current_field_marked_as_arabic_toggle: currentFieldMarkedAsArabic,
         current_field_marked_as_singular_toggle: currentFieldMarkedAsSingular,
         current_field_marked_as_unknown_toggle: currentFieldMarkedAsUnknown,

         current_field_marked_as_derived_toggle: currentFieldMarkedAsDerived,
         current_field_marked_as_plural_toggle: currentFieldMarkedAsPlural,
         current_field_marked_as_opposite_toggle: currentFieldMarkedAsOpposite,
         current_field_marked_as_related_toggle: currentFieldMarkedAsRelated,

         alternative_assigned_value: alternativeAssignedValue
      },
    });
  }

  getTextFieldType(ottoSpellingTextField) {
    if (ottoSpellingTextField.classList.contains('js-primary-ottoman-spelling-text-field')) {
      return 'primary';
    } else if (ottoSpellingTextField.classList.contains('js-simple-ottoman-alternative-value')) {
      return 'simple-alternate';
    } else if (ottoSpellingTextField.classList.contains('js-corrected-ottoman-spelling-text-field')) {
      return 'corrected';
    } else if (ottoSpellingTextField.classList.contains('js-corrected-ottoman-alternative-value')) {
      return 'corrected-alternate';
    }
  }

  // in this method we pass the current text field, and it returns the alternative_assigned_value of that field
  getAlternativeAssignedValue(targetTextField){
    const alternativeAssignedValueField = targetTextField.closest('.entries-input')?.querySelector('input.js-otto-spelling-assigned-number');
    let alternativeAssignedValue        = alternativeAssignedValueField ? alternativeAssignedValueField.value : null;
    alternativeAssignedValue            = parseInt(alternativeAssignedValue, 10);
    alternativeAssignedValue            = isNaN(alternativeAssignedValue) ? null : alternativeAssignedValue;    

    return alternativeAssignedValue;
  }

  handlePopulationOfAlternateSpellings(alternateSpellingsDataToPopulate, targetElement, alternativeAssignedValue) {
    if (alternateSpellingsDataToPopulate) {
      const alternateSpellingsWithDataToPopulate = [];
      const typingValueContainer = targetElement.closest('.js-typing-value-container');

      // TODO: for testing, remove after feature is stable and code is reviewed
      // if (this.identicalPopulatedAlternateSpellingExists(typingValueContainer, alternateSpellingsDataToPopulate)) {
      //   // early return to avoid recursive population
      //   return;
      // }

      // can be optimised
      Object.values(alternateSpellingsDataToPopulate).forEach(spellingData => {
        alternateSpellingsWithDataToPopulate.push({ 
          ottomanValue: spellingData.spelling, 
          spellingFlags: spellingData.flags,
          spellingSources: spellingData.spelling_sources 
        });
      });

      if (alternateSpellingsWithDataToPopulate.length > 0) {
        this.deleteExistingAlternateSpellingFields(typingValueContainer, alternativeAssignedValue);
        this.createAndPopulateAlternateSpellingFields(alternateSpellingsWithDataToPopulate, typingValueContainer)
      }
    }    
  }

  // TODO: for testing, remove after feature is stable and code is reviewed  
  // identicalPopulatedAlternateSpellingExists(typingValueContainer, alternateSpellingsDataToPopulate) {
  //   const alternateFields = typingValueContainer.querySelectorAll('.js-alternate-ottoman-spelling-text-field');
  //   const existingAlternateValues = new Set(Array.from(alternateFields).map(field => field.value));

  //   return Object.values(alternateSpellingsDataToPopulate).some(spellingData => {
  //     console.log(`Comparing: ${spellingData.spelling} with existing values: ${[...existingAlternateValues].join(', ')}`);
  //     const isMatch = existingAlternateValues.has(spellingData.spelling);
  //     console.log(`Match result: ${isMatch}`);
  //     return isMatch;
  //   });
  // }

  // identicalPopulatedAlternateSpellingExists(typingValueContainer, alternateSpellingsDataToPopulate) {
  //   const alternateFields         = typingValueContainer.querySelectorAll('.js-alternate-ottoman-spelling-text-field');
  //   const existingAlternateValues = new Set(Array.from(alternateFields).map(field => field.value));
    
  //   return Object.values(alternateSpellingsDataToPopulate).some(spellingData => existingAlternateValues.has(spellingData.spelling));
  // }

  removeSourcesIdentifierFromNewPopulatedAlternateSpellingField() {
    $('.js-unpopulated-history-sources').each(function() {
      $(this).removeClass('js-unpopulated-history-sources');
    });
  }

  getOtherBtnDropdownData() {
    const otherDropdownBtnValues                 = [];
    let otherDropdownOttomanSpellingValueOttoman = this.element.getAttribute('data-matched-spelling-ottoman-value'); 
    let otherDropdownOttomanSpellingFlags        = this.element.getAttribute('data-value-ottoman-field-flags');
    let otherDropdownOttomanSpellingSources      = JSON.parse(this.element.getAttribute('data-ottoman-spelling-sources'));
    otherDropdownBtnValues.push({otherDropdownOttomanSpellingValueOttoman, otherDropdownOttomanSpellingFlags, otherDropdownOttomanSpellingSources});

    // Getting dropdown's alternate spelling's values, flags and sources
    const otherDropdownBtnAlternateOttomanValueFields = this.element.querySelectorAll('.js-other-alternative-entry');
    otherDropdownBtnAlternateOttomanValueFields.forEach(alternateOttomanValueField => {
      otherDropdownOttomanSpellingValueOttoman = alternateOttomanValueField.getAttribute('data-alternate-ottoman-spelling-value-ottoman');
      otherDropdownOttomanSpellingFlags        = alternateOttomanValueField.getAttribute('data-alternate-ottoman-spelling-field-flags');
      otherDropdownOttomanSpellingSources      = JSON.parse(alternateOttomanValueField.getAttribute('data-alternate-ottoman-spelling-sources'));
      otherDropdownBtnValues.push({otherDropdownOttomanSpellingValueOttoman, otherDropdownOttomanSpellingFlags, otherDropdownOttomanSpellingSources});
    });

    return otherDropdownBtnValues;
  }

  showOtherBtn() {
    this.element.closest('.js-other-ottoman-parent-element')
    ?.querySelector('#otherOttomanValuesDropdownMenu')
    ?.classList.remove('hide_thumbnail');
  }

  removePopulatedOptionFromOtherBtnDropdown() {
    const otherDropdownData = this.getOtherBtnDropdownData(); // Getting dropdown's ottoman spelling's values, flags and sources
    const typeOTextFieldsData = this.getPopulatedTextFieldData();// Getting prepopulated container ottoman spelling's values, flags and sources 
    
    // hiding option with same ottoman values as text field ottoman values
    if (this.areDropdownAndTextFieldOttomanSpellingValuesSame(typeOTextFieldsData, otherDropdownData)){
      this.hideIdenticalOttomanSpellingOptionsFromOtherDropdown();
    } 
  }

  getPopulatedTextFieldData() {
    const prePopulatedContainer = this.element.closest('.js-typing-value-container');

    // Getting prepopulated container ottoman values
    const ottomanSpellingTextFieldsFlagsAndValues = [];
    const ottomanSpellingTextFieldsContainer      = prePopulatedContainer?.querySelector('.js-ottoman-entries-input'); 
    const textFieldSpellingSourcesConatiners      = prePopulatedContainer?.querySelectorAll('.js-latin-history-sources');
    let textFieldOttomanValue                     = prePopulatedContainer?.querySelector('.js-primary-ottoman-spelling-text-field').value; 
    let containerFlags                            = []; // toggle flag buttons are not available on primary ottoman value
    let textFieldSpellingSourcesValues            = [];
    let ottomanSpellingFieldSources               = ""; // sources are not available on primary ottoman spellings

    // getting ottoman value fields spelling sources
    if (textFieldSpellingSourcesConatiners) {
      textFieldSpellingSourcesConatiners.forEach(textFieldSourcesConatiner => {
        textFieldSpellingSourcesValues.push(this.getTypeOSources(textFieldSourcesConatiner.querySelectorAll('.js-input-group')));
      });
    }

    ottomanSpellingTextFieldsFlagsAndValues.push({textFieldOttomanValue, containerFlags, ottomanSpellingFieldSources}); 

    if(prePopulatedContainer){
      // getting alternate ottoman spelling's values, flags and sources
      const alternateOttomanSpellingTextFieldsContainer = prePopulatedContainer.querySelectorAll('.js-alternative-typing-fields');
      alternateOttomanSpellingTextFieldsContainer.forEach((existingAlternateOttomanSpellingContainer, index) => {
        const alternateTextField = existingAlternateOttomanSpellingContainer.querySelector('.js-alternate-ottoman-spelling-text-field');
        if (alternateTextField) {
          textFieldOttomanValue       = alternateTextField.value
          containerFlags              = this.getOttomanSpellingFlags(existingAlternateOttomanSpellingContainer);
          ottomanSpellingFieldSources = textFieldSpellingSourcesValues[index];
          ottomanSpellingTextFieldsFlagsAndValues.push({textFieldOttomanValue, containerFlags, ottomanSpellingFieldSources});
        }
      });
    }

    return ottomanSpellingTextFieldsFlagsAndValues;
  }

  hideIdenticalOttomanSpellingOptionsFromOtherDropdown() {
    this.element.classList.add('collapse');

    // removing other button in case dropdown gets empty
    this.checkAndRemoveOtherButton();
  }

  getOttomanSpellingFlags(ottomanSpellingTextFieldsContainer){
    const marked_as_root         = ottomanSpellingTextFieldsContainer.querySelector('input.js-marked-as-root-toggle').checked;
    const marked_as_root_letter  = ottomanSpellingTextFieldsContainer.querySelector('input.js-marked-as-root-letter-toggle')?.checked;
    const marked_as_arabic       = ottomanSpellingTextFieldsContainer.querySelector('input.js-marked-as-arabic-toggle').checked;
    const marked_as_persian      = ottomanSpellingTextFieldsContainer.querySelector('input.js-marked-as-persian-toggle').checked;
    const marked_as_turkish      = ottomanSpellingTextFieldsContainer.querySelector('input.js-marked-as-turkish-toggle').checked;
    const marked_as_singular     = ottomanSpellingTextFieldsContainer.querySelector('input.js-marked-as-singular-toggle').checked;
    const marked_as_unknown      = ottomanSpellingTextFieldsContainer.querySelector('input.js-marked-as-unknown-toggle').checked;
    const marked_as_derived      = ottomanSpellingTextFieldsContainer.querySelector('input.js-marked-as-derived-value-toggle').checked;
    const marked_as_plural       = ottomanSpellingTextFieldsContainer.querySelector('input.js-marked-as-plural-value-toggle').checked;
    const marked_as_opposite     = ottomanSpellingTextFieldsContainer.querySelector('input.js-marked-as-opposite-value-toggle').checked;
    const marked_as_related      = ottomanSpellingTextFieldsContainer.querySelector('input.js-marked-as-related-value-toggle').checked;
    const marked_as_alternate    = ottomanSpellingTextFieldsContainer.querySelector('input.js-marked-as-alternate-value-toggle').checked;
    const marked_as_synonym      = ottomanSpellingTextFieldsContainer.querySelector('input.js-marked-as-synonym-value-toggle').checked;

    return this.typeOTypingFieldFlags(marked_as_root, marked_as_singular, marked_as_root_letter, marked_as_arabic, marked_as_persian, marked_as_turkish, marked_as_unknown, marked_as_derived, marked_as_plural, marked_as_opposite, marked_as_related, marked_as_alternate, marked_as_synonym);
  }

  typeOTypingFieldFlags(marked_as_root, marked_as_singular, marked_as_root_letter, marked_as_arabic, marked_as_persian, marked_as_turkish, marked_as_unknown, marked_as_derived, marked_as_plural, marked_as_opposite, marked_as_related, marked_as_alternate, marked_as_synonym) {
    let typingFieldFlags = [];
    
    // order in which flags are pushed is crucial here
    // TODO: compare flags irrespective of order 
    if (marked_as_root) {
      typingFieldFlags.push('Root');
    }

    if (marked_as_singular) {
      typingFieldFlags.push('Sing.');
    }

    if (marked_as_root_letter) {
      typingFieldFlags.push('R.Ltr.');
    }

    if (marked_as_arabic) {
      typingFieldFlags.push('Arb.');
    }

    if (marked_as_persian) {
      typingFieldFlags.push('Per.');
    }

    if (marked_as_turkish) {
      typingFieldFlags.push('Trk.');
    }

    if (marked_as_unknown) {
      typingFieldFlags.push('?');
    }

    if (marked_as_derived) {
      typingFieldFlags.push('Der.');
    }

    if (marked_as_plural) {
      typingFieldFlags.push('Plr.');
    }

    if (marked_as_opposite) {
      typingFieldFlags.push('Opp.');
    }

    if (marked_as_related) {
      typingFieldFlags.push('Rel.');
    }

    if (marked_as_alternate) {
      typingFieldFlags.push('Alt.');
    }

    if (marked_as_synonym) {
      typingFieldFlags.push('Syn.');
    }

    return typingFieldFlags.join(', ');
  }

  getTypeOSources(sourcesConatiner) {
    const allSourcesValue = [];
    sourcesConatiner.forEach((source, index) => {
      const sourceInputs = source.querySelectorAll('.js-source-input');
      const sourceValue = [];
      sourceInputs.forEach((sourceInput, inputIndex) => {
        if (sourceInput.type != 'hidden') {
          sourceValue.push(sourceInput.value)
        } 
      });
      allSourcesValue.push(sourceValue)
    });

    // getting sources values that has url or page no
    const filteredSourcesValue = allSourcesValue.filter(sourceValue => {
      return sourceValue.some((value, index) => index !== 0 && value.trim() !== '');
    });
    
    return filteredSourcesValue;
  }

  isBlankDropdownValues(dropdownValues) {
    // Check if dropdownValues is an array
    if (!Array.isArray(dropdownValues)) {
      return false;
    }

    // Check if all values in an object are empty
    const isObjectEmpty = obj => Object.values(obj).every(value => value === '');

    // Check if all objects in the array are empty
    return dropdownValues.every(isObjectEmpty);
  }

  // while compairing two sources from different items, in some cases source values are undefined and in some other cases source values are null, this method equivalize them with empty string for smooth comparison
  normalizeNullOrUndefined(value) {
    return value == null ? '' : value;
  }

  areSpellingSourcesSame(textFieldSpellingSources, dropdownOptionSpellingSources) {
    let currentPopulatedValueSpellingSources = textFieldSpellingSources
    let dropdownValueSpellingSources = dropdownOptionSpellingSources

    // creating a Map to store ottoman spelling sources
    let populatedValueSpellingSourceMap = new Map();
    for (let source of currentPopulatedValueSpellingSources) {
      // let key = `${source[0]}|${source[1]}|${source[2]}`;
      let key = `${this.normalizeNullOrUndefined(source[0])}|${this.normalizeNullOrUndefined(source[1])}|${this.normalizeNullOrUndefined(source[2])}`;
      populatedValueSpellingSourceMap.set(key, true);
    }

    // creating a Map to store other sources
    let dropdowndownValueSpellingSourceMap = new Map();
    for (let source of dropdownValueSpellingSources) {
      // let key = `${source.dictionary_name}|${source.url}|${source.page_no}`;
      let key = `${this.normalizeNullOrUndefined(source.dictionary_name)}|${this.normalizeNullOrUndefined(source.url)}|${this.normalizeNullOrUndefined(source.page_no)}`;
      dropdowndownValueSpellingSourceMap.set(key, true);
    }

    // comparing the size of the two Maps
    if (populatedValueSpellingSourceMap.size !== dropdowndownValueSpellingSourceMap.size) {
      return false;
    }

    // comparing the keys in both Maps
    for (let key of populatedValueSpellingSourceMap.keys()) {
      if (!dropdowndownValueSpellingSourceMap.has(key)) {
        return false;
      }
    }

    return true;
  }

  areDropdownAndTextFieldOttomanSpellingValuesSame(ottomanSpellingTextFieldsFlagsAndValues, otherDropdownValues){
    if (ottomanSpellingTextFieldsFlagsAndValues.length != otherDropdownValues.length){
      return false;
    }
    
    for (let i = 0; i < ottomanSpellingTextFieldsFlagsAndValues.length; i++) {
      const textFieldContainerValues = ottomanSpellingTextFieldsFlagsAndValues[i];
      const dropdownOptionValues = otherDropdownValues[i];
      
      // TODO: modify this code, compare strings irrespective of order
      // return if flags are not same
      if (textFieldContainerValues.containerFlags != dropdownOptionValues.otherDropdownOttomanSpellingFlags){
        return false;
      }      

      // return if latin values are not same
      if (textFieldContainerValues.textFieldOttomanValue != dropdownOptionValues.otherDropdownOttomanSpellingValueOttoman ) {
          return false;
      }

      // return if sources are not same
      if(textFieldContainerValues.ottomanSpellingFieldSources.length != dropdownOptionValues.otherDropdownOttomanSpellingSources.length) {
        return false;
      }

      if (!this.areSpellingSourcesSame(textFieldContainerValues.ottomanSpellingFieldSources, dropdownOptionValues.otherDropdownOttomanSpellingSources)){
        return false
      }
    }

    return true;
  }

  // //Method to populate ottoman text fields with selected values
  populateAndHandleVisibilityOfOttomanValuesFromDropdown(e){
    const selectedOttomanSpellingOption     = event.currentTarget;
    const entriesInputContainer             = selectedOttomanSpellingOption.closest('.js-otto-container');
    const currentTextField                  = entriesInputContainer.querySelector('.js-text-field')

    // Fetching typing field container to update ottoman values 
    const typingFieldContainer          = selectedOttomanSpellingOption.closest('.js-typing-value-container');

    let alternateAssignedValue          = this.getAlternativeAssignedValue(currentTextField);

    // delete existing alternate spellings
    this.deleteExistingAlternateSpellingFields(typingFieldContainer, alternateAssignedValue);
    this.setValuesInOttomanSpellingFields(selectedOttomanSpellingOption, typingFieldContainer, alternateAssignedValue);
    this.handleOtherDropdownOptionsVisibility();
  }

  setValuesInOttomanSpellingFields(selectedOttomanSpellingOption, typingFieldContainer, alternativeAssignedValue = null){
    this.setPrimaryOttomanSpellingFieldWithSelectedData(selectedOttomanSpellingOption, typingFieldContainer, alternativeAssignedValue);
    this.setAlternateOttomanSpellingFieldWithSelectedData(selectedOttomanSpellingOption, typingFieldContainer, alternativeAssignedValue);
  }

  // TODO: rename this method, now its functionality is not specific to primary spelling input field
  setPrimaryOttomanSpellingFieldWithSelectedData(selectedOttomanSpellingOption, typingFieldContainer, alternativeAssignedValue = null){
    const entriesInputContainer             = selectedOttomanSpellingOption.closest('.entries-input');
    const currentTextField                  = entriesInputContainer.querySelector('.js-text-field')

    const historiesSourcesContainerId       = selectedOttomanSpellingOption.getAttribute('data-transcription-sources-field-id');
    const isCorrectedField                  = selectedOttomanSpellingOption.getAttribute('data-corrrected-field') === 'true';
    const selectedOttomanSpellingValue      = selectedOttomanSpellingOption.getAttribute('data-matched-spelling-ottoman-value');
    const selectedOttomanSpellingFieldflags = selectedOttomanSpellingOption.getAttribute('data-value-ottoman-field-flags');
    const selectedOttomanSpellingSources    = JSON.parse(selectedOttomanSpellingOption.getAttribute('data-ottoman-spelling-sources'));

    this.setOttomanSpellingFieldWithDataToPopulate(currentTextField, selectedOttomanSpellingFieldflags, selectedOttomanSpellingValue, 
                             selectedOttomanSpellingSources, typingFieldContainer, historiesSourcesContainerId, isCorrectedField, alternativeAssignedValue);
    const sourcesButton = typingFieldContainer.querySelector('.js-history-sources-btn')

    if (sourcesButton){
      if (selectedOttomanSpellingSources.length > 0){
        sourcesButton.classList.add('text-danger')
      } else {
        sourcesButton.classList.remove('text-danger')
      }
    }
  }

  setAlternateOttomanSpellingFieldWithSelectedData(selectedOttomanSpellingOption, typingFieldContainer){
    const selectedAlternateSpellingsWithDataToPopulate = [];
    // getting selected alternative latin values and flags  
    selectedOttomanSpellingOption.querySelectorAll('.js-other-alternative-entry[data-alternate-ottoman-spelling-value-ottoman]').forEach(alternateSpelling => {
      const ottomanValue    = alternateSpelling.getAttribute('data-alternate-ottoman-spelling-value-ottoman');
      const spellingFlags   = alternateSpelling.getAttribute('data-alternate-ottoman-spelling-field-flags');
      const spellingSources = JSON.parse(alternateSpelling.getAttribute('data-alternate-ottoman-spelling-sources'));

      selectedAlternateSpellingsWithDataToPopulate.push({ ottomanValue, spellingFlags, spellingSources });
    });

    if(selectedAlternateSpellingsWithDataToPopulate.length > 0) { // create alternative fields if option has alternative fields
      // creating new alternative fields with selected text and flags and history sources
      this.createAndPopulateAlternateSpellingFields(selectedAlternateSpellingsWithDataToPopulate, typingFieldContainer);
    }
  }

  handleOtherDropdownOptionsVisibility(){
    const selectedOttomanFields = this.element.closest('.js-other-ottoman-spelling-options-dropdown').querySelectorAll('.js-type-o-other-values-grid-items.collapse'); 
    if (selectedOttomanFields.length > 0) {
      selectedOttomanFields.forEach(selectedOttomanField => {
        selectedOttomanField.classList.remove('collapse');
      });
    }
    this.element.classList.add('collapse');
    // remove other btn if only option is selected
    this.checkAndRemoveOtherButton();
  }

  checkAndRemoveOtherButton() {
    if (this.element.closest('.js-other-ottoman-spelling-options-dropdown').querySelectorAll('.js-type-o-other-values-grid-items.collapse').length == this.element.closest('.js-other-ottoman-spelling-options-dropdown').querySelectorAll('.js-type-o-other-values-grid-items').length){
      this.element.closest('.js-other-ottoman-parent-element').querySelector('#otherOttomanValuesDropdownMenu').classList.add('hide_thumbnail');
    } else {
      // displaying dropdown that was initially hidden to avoid flicker/blink effect
      this.element.closest('.js-other-ottoman-parent-element').classList.remove('d-none');
    }
  }

  deleteExistingAlternateSpellingFields(typingFieldContainer, alternativeAssignedValue = null) {
    const alternateOttomnSpellingFields = typingFieldContainer.querySelectorAll('.js-entries-input');
    if (alternateOttomnSpellingFields.length > 0) {
      alternateOttomnSpellingFields.forEach(field => {
        const assignedValueField = field.querySelector('.js-otto-spelling-assigned-number').value;
        if (assignedValueField) {
          const fieldAssignedValue      = parseInt(assignedValueField, 10);
          const alternateFieldDeleteBtn = field.querySelector('.js-remove-alternative-entry');
          // Check if alternativeAssignedValue is null or fieldAssignedValue is greater than or equal to it
          if (alternativeAssignedValue == null || fieldAssignedValue > alternativeAssignedValue) {
            if (alternateFieldDeleteBtn) {
              alternateFieldDeleteBtn.click();
            }
          }
        }
      });
    }
  }

  createAndPopulateAlternateSpellingFields(alternateSpellingsWithDataToPopulate, typingFieldContainer) {
    const addAlternateFieldsPromises = alternateSpellingsWithDataToPopulate.map((alternate_spelling) => {
      return new Promise((resolve) => {
        const addAlternateOttomanSpellingBtn = typingFieldContainer.querySelector('.js-btn-add-alternate');
        addAlternateOttomanSpellingBtn.click();

        let alternateTextFields = typingFieldContainer.querySelectorAll('.js-alternate-ottoman-spelling-text-field');
        let currentFocusedAlternateSpellingTextField = alternateTextFields[alternateTextFields.length - 1];

        if (currentFocusedAlternateSpellingTextField) {
          const selectedTypingField = currentFocusedAlternateSpellingTextField.closest('.js-entries-input');
          
          setTimeout(() => {
            const spellingSourcesContainerId = '0000.js-unpopulated-history-sources';
            this.setOttomanSpellingFieldWithDataToPopulate(
              currentFocusedAlternateSpellingTextField, 
              alternate_spelling.spellingFlags, 
              alternate_spelling.ottomanValue, 
              alternate_spelling.spellingSources,
              typingFieldContainer, 
              spellingSourcesContainerId, 
              false
            );

            const sourcesButton = selectedTypingField.querySelector('.js-alternate-history-sources-btn');
            if (sourcesButton) {
              if (alternate_spelling.spellingSources.length > 0) {
                sourcesButton.classList.add('text-danger');            
              } else {
                sourcesButton.classList.remove('text-danger');
              }
            }
            resolve(); // Resolve the promise when this alternate spelling has been populated
          }, 10);
        } else {
          resolve(); // Resolve immediately if no text field was found
        }
      });
    });

    // After all alternates are populated, call showCommaSeparatedTextForRootLetterFields
    // Promise.all(addAlternateFieldsPromises).then(() => {
    //   this.showCommaSeparatedTextForRootLetterFields(typingFieldContainer);
    // });
  }

  // TODO: Remove this commented code after PR is reviewed
  // createAndPopulateAlternateSpellingFields(alternateSpellingsWithDataToPopulate, typingFieldContainer) {
  //   alternateSpellingsWithDataToPopulate.forEach(function(alternate_spelling) {
      
  //     typingFieldContainer.querySelector('.js-btn-add-alternate').click();

  //     let alternateTextFields = typingFieldContainer.querySelectorAll('.js-alternate-ottoman-spelling-text-field');
  //     let currentFocusedAlternateSpellingTextField = alternateTextFields[alternateTextFields.length - 1];

  //     if (currentFocusedAlternateSpellingTextField) {
  //       const selectedTypingField = currentFocusedAlternateSpellingTextField.closest('.js-entries-input');
  //       // selectedTypingField.focus();
  //       // Wait a moment for sources to render if necessary
  //       setTimeout(() => {
  //         const spellingSourcesContainerId = '0000.js-unpopulated-history-sources'; // new sources created by add alternative btn has ID '0000'
  //         this.setOttomanSpellingFieldWithDataToPopulate(
  //           currentFocusedAlternateSpellingTextField, 
  //           alternate_spelling.spellingFlags, 
  //           alternate_spelling.ottomanValue, 
  //           alternate_spelling.spellingSources,
  //           typingFieldContainer, 
  //           spellingSourcesContainerId, 
  //           false
  //         );

  //         const sourcesButton = selectedTypingField.querySelector('.js-alternate-history-sources-btn');
  //         if (sourcesButton) {
  //           if (alternate_spelling.spellingSources.length > 0) {
  //             sourcesButton.classList.add('text-danger');            
  //           } else {
  //             sourcesButton.classList.remove('text-danger');
  //           }          
  //         }
  //       }, 10);
  //     }
  //   }.bind(this));

  //   this.checkAndProcessRootLetterToggleFields(typingFieldContainer);
  // }

  setOttomanSpellingFieldWithDataToPopulate( typingField, typingFieldFlags, typingFieldText, typingFieldSpellingSources, 
                          typingFieldContainer, spellingSourcesContainerId, isCorrected, alternativeAssignedValue = null){
    
    typingField.value = typingFieldText;
    if (alternativeAssignedValue == -1){
      return
    }

    this.setFlagsOfTypingField(typingField, typingFieldFlags);
    this.setSpellingSourcesOfTypingField(typingFieldSpellingSources, typingFieldContainer, spellingSourcesContainerId, isCorrected);
  }

  setFlagsOfTypingField(typingField, typingFieldFlags){
    const typingContainer = typingField.closest('.js-entries-input');
    
    const flagContainers   = typingContainer.querySelectorAll('.js-otto-toggles-parent-container');

    const toggleToUpdate  = {
                              'Root': '.js-marked-as-root-toggle',
                              'R.Ltr.': '.js-marked-as-root-letter-toggle',
                              'Arb.': '.js-marked-as-arabic-toggle',
                              'Per.': '.js-marked-as-persian-toggle',
                              'Trk.': '.js-marked-as-turkish-toggle',
                              'Sing.': '.js-marked-as-singular-toggle',
                              '?': '.js-marked-as-unknown-toggle',
                              'Der.': '.js-marked-as-derived-value-toggle',
                              'Plr.': '.js-marked-as-plural-value-toggle',
                              'Opp.': '.js-marked-as-opposite-value-toggle',
                              'Rel.': '.js-marked-as-related-value-toggle',
                              'Alt.': '.js-marked-as-alternate-value-toggle',
                              'Syn.': '.js-marked-as-synonym-value-toggle'
                            };

    flagContainers.forEach(flagContainer => {
      Object.entries(toggleToUpdate).forEach(([toggleFlag, toggleClass]) => {
        const toggleElement = flagContainer.querySelector(toggleClass);

        if (toggleElement) {
          toggleElement.checked = typingFieldFlags.includes(toggleFlag);
          
          if (toggleFlag === 'R.Ltr.' && toggleElement.checked) {
            const event = { target: typingField };
            if (window.typing_form && typeof window.typing_form.showDuplicateAndHideOriginalAlternateIfRootLtr === 'function') {
              window.typing_form.showDuplicateAndHideOriginalAlternateIfRootLtr(event);
            }
          }
        }

        // flagContainer.querySelector(toggleClass).checked = typingFieldFlags.includes(toggleFlag);     
      });
    });
  }

  setSpellingSourcesOfTypingField(typingFieldSpellingSources, typingFieldContainer, containerClass, isCorrected) {
    const modal = document.querySelector('.js-latin-history-modal');
    let focusedFieldSpellingSourcesContainter = modal?.querySelector('.js-latin-history-sources');

    // let focusedHistoriesSourcesContainer = typingFieldContainer.querySelector(`.${containerClass}`);
    // let focusedFieldSpellingSourcesContainter = typingFieldContainer.querySelector('.js-latin-history-sources');
    const isAlternateLatinFieldContainer = containerClass == '0000.js-unpopulated-history-sources'; 
    // removing 'js-unpopulated-history-sources' to make unique identifier of alternative latin value container 
    if (isAlternateLatinFieldContainer) {
      focusedFieldSpellingSourcesContainter = typingFieldContainer.querySelector('.js-latin-history-sources.js-unpopulated-history-sources');
      focusedFieldSpellingSourcesContainter.classList.remove('js-unpopulated-history-sources')
    }

    let existingSourcesFields = focusedFieldSpellingSourcesContainter.querySelectorAll('.js-nested-form-wrapper');
    this.resetExistingSources(existingSourcesFields);
    
    if (typingFieldSpellingSources.length > 0) {
      this.populateSpellingSources(typingFieldSpellingSources, focusedFieldSpellingSourcesContainter);
    }
  }
  
  resetExistingSources(existingSourcesFields ){
    // resetting existing url and page number valuess
    for (let i = 0; i < existingSourcesFields.length; i++ ) {
      const urlInput = existingSourcesFields[i].querySelector('.js-source-url-input');
      const pageNoInput = existingSourcesFields[i].querySelector('.js-source-page-no-input');
      if (urlInput) urlInput.value = '';
      if (pageNoInput) pageNoInput.value = '';
      
      // deleting custom sources field
      //if (i > 4) existingSourcesFields[i].querySelector('.js-history-source-remove-field-btn').click();
      if (i > 4) {
        const removeBtn = existingSourcesFields[i].querySelector('.js-history-source-remove-field-btn');
        if (removeBtn) {
          removeBtn.click();
        }
      }
    }
  }

  getVisibleSources(spellingSourcesContainer){
    return $(spellingSourcesContainer).find('.js-nested-form-wrapper').filter(function() {
      return $(this).css('display') !== 'none';
    });
  }

  populateSpellingSources(typingFieldSpellingSources, focusedFieldSpellingSourcesContainter){
    // getting visible history sources fields
    const existingSourcesFields = this.getVisibleSources(focusedFieldSpellingSourcesContainter);
    for (let i = 0; i < typingFieldSpellingSources.length; i++ ) {
      let isCustomSource = true;
      // populate default sources fields
      for (let j = 0; j < existingSourcesFields.length; j++) {
        const dictionaryNameInput = existingSourcesFields[j].querySelector('.js-source-dictionary-name-input');
        if (dictionaryNameInput && dictionaryNameInput.value === typingFieldSpellingSources[i].dictionary_name) {
          const urlInput = existingSourcesFields[j].querySelector('.js-source-url-input');
          const pageNoInput = existingSourcesFields[j].querySelector('.js-source-page-no-input');
          
          if (urlInput) urlInput.value = typingFieldSpellingSources[i].url;
          if (pageNoInput) pageNoInput.value = typingFieldSpellingSources[i].page_no;
          
          isCustomSource = false;
          break;
        }
      }
      // If no matching dictionary_name was found, create new source field
      if (isCustomSource) {
        focusedFieldSpellingSourcesContainter.querySelector('.js-add-history-sources-field-btn').click();
        const newSourceField  = this.getVisibleSources(focusedFieldSpellingSourcesContainter).last()[0];
        const dictionaryInput = newSourceField.querySelector(".js-source-dictionary-name-input");
        const urlInput        = newSourceField.querySelector('.js-source-url-input');
        const pageNoInput     = newSourceField.querySelector('.js-source-page-no-input');
        dictionaryInput.value = typingFieldSpellingSources[i].dictionary_name;
        urlInput.value        = typingFieldSpellingSources[i].url;
        pageNoInput.value     = typingFieldSpellingSources[i].page_no;
      }
    }
  }

  // TODO: now we are handling this requirement while setting the toggle flags, the code is kept temporarily, remove after code is reviewed
  showCommaSeparatedTextForRootLetterFields(typingFieldContainer) {
    const alternateFields = typingFieldContainer.querySelectorAll('.js-alternate-ottoman-spelling-text-field');
    alternateFields.forEach((textField) => {
      const container = textField.closest('.entries-input');
      if (container) {
        const rootLetterToggle = container.querySelector('.js-root-letter-toggle');
        // Check if the root letter toggle is on
        // disabled (using 'false &&') to prevent execution as this functionality is not required currently.
        if (false && rootLetterToggle && rootLetterToggle.checked) {
          const event = { target: textField };
          if (window.typing_form && typeof window.typing_form.showDuplicateAndHideOriginalAlternateIfRootLtr === 'function') {
            window.typing_form.showDuplicateAndHideOriginalAlternateIfRootLtr(event);
          }
        }
      }
    });
  }
}
